<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('member_wait_deposit.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('member_wait_deposit.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input v-model="valDateStart" ref="startDate" :date="dateFrom"
                :label="$t('member_wait_deposit.date_from')" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input v-model="valDateEnd" ref="endDate" :date="dateTo"
                :label="$t('member_wait_deposit.date_to')" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" @click="searchDeposit" class=""> {{ $t('search') }} </v-btn>
            <!-- <v-btn color="warning" @click="exportCsv" class="">ออกรายงาน Excel</v-btn> -->
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5"> {{ $t('search') }} :</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.currentPage" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="statement">
            <template v-slot:[`item.account_number`]="{ item }">
              <!-- <div class="p-0 d-flex row" style="align-items: center">
                <div class="col col-12 col-lg-3 col-sm-12 p-0 img-bank">
                  <img v-if="item.account_bank" alt="Avatar" width="25px"
                    :src="require(`@/assets/images/logos/banks/${item.account_bank}.png`)" />
                </div>
                <div class="col col-12 col-lg-9 col-sm-12 p-0 detail-bank">
                  <p class="detail-bank-p">{{ item.account_number && item.account_number }}</p>
                </div>
              </div> -->

              <div class="ma-auto" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                <div v-if="!item.bankCountry">
                  <img v-if="item.account_bank" alt="bank_img"
                    style="object-fit: contain;height: inherit;width: 100%;"
                    :src="require(`@/assets/images/logos/banks/${item.account_bank}.png`)" />
                </div>
                <div v-else>
                  <img v-if="item.account_bank" alt="bank_img"
                    style="object-fit: contain;height: inherit;width: 100%;"
                    :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.account_bank}.webp`" />
                </div>
              </div>
              <span>{{ item.account_number && item.account_number }}</span>
            </template>
            <template v-slot:[`item.before_amount`]="{ item }">
              {{ item.before_amount | currency }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              {{ item.total | currency }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <div class="amount-col">
                <span class="success--text" v-if="item.type"> <v-icon class="success--text">mdi-arrow-up</v-icon></span>
                <label v-if="(item.amount > 0)">{{ item.amount | currency }}</label>
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex">
                <v-btn color="success" dark @click="creditd(item)"
                  :loading="loadingSubmit" v-if="item.action.hold || item.action.amount > 0">
                  <v-icon small color="white">mdi-square-edit-outline</v-icon>
                  <span class="px-3 white--text">{{ $t('member_wait_deposit.deposit') }}</span>
                </v-btn>
                <v-btn color="error" class="ml-1"
                  @click="cancelStampDeposit(item, item.action.member_id, !item.promotion ? false : item.action.promotion_match,)"
                  :loading="loadingCancel"
                  v-if="!item.action.hold || (item.action.hold && !item.action.promotion_match) || !item.promotion">
                  {{ $t('member_wait_deposit.cancel') }}
                </v-btn>
              </div>
            </template>
            <template  v-slot:[`item.slip_url`]="{ item }">
              <img v-if="item.slip_payment && item.slip_url" class="preview-slip-img" :src="item.slip_url" :alt="item.slip_url" @click="displayFullImage(item.slip_url)" />
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      dateNow: moment().format('YY-MM-DD'),
      formValid: true,
      btnl: false,
      date: null,
      currentMemberDetail: {},
      afterCredit: '',
      typeItem: '',
      typeBank: '',
      waitCredit: '',
      afterAmount: '',
      amount: '',
      timeNow: '00:00:00',
      searchData: null,
      valDateStart: null,
      valDateEnd: null,
      typeItems: [
        {
          name: this.$t('increase'),
          value: 'YES',
        },
        {
          name: this.$t('decrease'),
          value: 'NO',
        },
      ],
      acceptItem: '',
      acceptItems: [
        {
          text: this.$t('yes'),
          value: true,
        },
        {
          text: this.$t('not'),
          value: false,
        },
      ],
      headers: [
        {
          text: this.$t('member_wait_deposit.header1'),
          value: 'member',
          width: '70px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header2'),
          value: 'account_number',
          width: '120px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header3'),
          value: 'promotion',
          width: '110px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header4'),
          value: 'amount',
          width: '150px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header9'),
          value: 'slip_url',
          width: '140px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header5'),
          value: 'startTime',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header6'),
          value: 'endTime',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header7'),
          value: 'transection_time',
          width: '105px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('member_wait_deposit.header8'),
          value: 'hold',
          width: '60px',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          width: '100px',
          align: 'center',
          sortable: false,
        },
      ],
      bankKey: this.$store.getters.getKeyBank,
      statement: [],
      members: [],
      memberId: '',
      loading: false,
      loadingMember: false,
      loadingSubmit: false,
      loadingBank: false,
      loadingPromotion: false,
      loadingCancel: false,
      dummy: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      flag_interval: null,
      bankItems: [],
      promotionItems: [],
      promotion: null,
      bankSystem: ''
    }
  },

  async created() {
    this.getWebSettingInfo()
    this.addLogPage()
    this.getAccountBankDeposit()
    this.getPromotionUse()
    this.searchUser()
    this.refreshdata()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getDepositData(newValue.page, newValue.itemsPerPage, this.$refs.startDate?.value, this.$refs.endDate?.value, this.searchData)
        if (cont && !this.dummy)
          await this.getDepositData(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate?.value,
            this.$refs.endDate?.value,
            this.searchData,
          )
        if (cont && this.dummy)
          await this.getDepositData(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate?.value,
            this.$refs.endDate?.value,
            this.searchData,
          )
      },
      deep: true,
    },
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'รายการรอฝาก',
            url: window.location.href,
            detail: 'ระบบจัดการรายการรอฝาก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getAccountBankDeposit() {
      this.loadingBank = true

      try {
        const rs = await this.$store.dispatch('getAccountBankDeposit')
        rs.map(el => {
          this.bankItems.push({
            value: el.id,
            label: `${el.bank} | ${el.bank_name} : ${el.bank_account}`,
          })
        })
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }

      this.loadingBank = false
    },
    async searchDeposit() {
      this.dummy = false
      await this.getDepositData(
        this.pagination.page,
        this.pagination.rowsPerPage,
        this.$refs.startDate?.value,
        this.$refs.endDate?.value,
        this.searchData,
      )
      this.pagination.currentPage = 1
    },
    async getDepositData(page, row, time_from, time_to, search) {
      this.valDateStart = this.$refs.startDate?.value
      this.valDateEnd = this.$refs.endDate?.value
      if (page !== 1 || search) {
        clearInterval(this.flag_interval)
      }
      try {
        this.loading = true
        if (this.$route.name === 'user-deposit') {
          const params = {
            page,
            row,
            ...(!search ? { page: page } : {}),
            ...(!search ? { rows: row } : {}),
            ...(page ? { page: page } : {}),
            ...(row ? { rows: row } : {}),
            ...(!search ? { time_from: this.formatDateYYYY(this.$refs.startDate?.value) } : {}),
            ...(!search ? { time_to: this.formatDateYYYY(this.$refs.endDate?.value) } : {}),
            status: 'success',
            ...(search ? { search: search } : {}),
          }
          let rs = await this.$store.dispatch('getStampDeposit', params)
          this.pagination.totalItems = rs.count
          this.statement = []

          rs.data.forEach(element => {
            let splitCountry, splitBankCode;
            if (element.user && element.user?.bankCode) {
              const code = element.user.bankCode;
              const checkCountryCode = (code) => {
                const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
                const firstTwoChars = code.substring(0, 2);
                return validCodes.includes(firstTwoChars);
              }

              if (checkCountryCode(code)) {
                splitCountry = code.slice(0, 2);
                splitBankCode = code.slice(2);
              } else {
                splitCountry = this.bankSystem;
                splitBankCode = code;
              }

              element.user.bankCountry = splitCountry.toLowerCase();
              element.user.bank = splitBankCode;
            }

            this.statement.push({
              id: element.id,
              amount: element.amount,
              type: element.amount > 0 ? true : false,
              member: element.user?.phone,
              admin: element.admin ? element.admin.name : element.transection_time ? 'AUTO' : null,
              date: element.createdAt ? moment(element.createdAt).format('YY-MM-DD HH:mm') : null,
              dateUpdate: element.updatedAt ? moment(element.updatedAt).format('YY-MM-DD HH:mm') : null,
              transection_time: element.transection_time ? moment(element.transection_time).format('YY-MM-DD HH:mm:ss') : null,
              account_number: element.user?.bankNumber ? element.user?.bankNumber : null,
              account_name: element.user?.bankName ? element.user?.bankName : null,
              account_bank: element.user?.bank ? element.user?.bank : null,
              bankCountry: element.user?.bankCountry ? element.user?.bankCountry : null,
              hold: element.hold === 1 ? 'ใช่' : '',
              startTime: moment(element.start_time).format('YY-MM-DD HH:mm:ss'),
              endTime: moment(element.end_time).format('YY-MM-DD HH:mm:ss'),
              promotion: element.promotion_name,
              slip_url: element.slip_url,
              slip_payment: element.slip_payment,
              action: {
                amount: element.amount,
                promotion_id: element.promotion_id,
                member_id: element.user?.id,
                hold: element.hold,
                promotion_match: element.promotion_match
              }
            })
          })
        } else {
          clearInterval(this.flag_interval)
        }
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      this.loading = false
    },
    async searchUser(search) {
      this.loadingMember = true
      let rs = await this.$store.dispatch('getMember', {
        page: 1,
        rows: 25,
        type: 'member',
        ...(search ? { search: search } : {}),
      })
      if (rs.count > 0) {
        this.members = []
        rs.data.forEach(element => {
          this.members.push({
            value: element.id,
            label: `${this.bankKey[element.bank] ? this.bankKey[element.bank] : element.bank} ${element.bank_account
              } : ${element.phone} - ${element.name}`,
          })
        })
      }
      this.loadingMember = false
    },
    async creditd(data) {
      this.$swal.fire({
        title: this.$t('member_wait_deposit.confirm_deposit'),
        showCancelButton: true,
        confirmButtonText: this.$t('member_wait_deposit.deposit'),
        cancelButtonText: this.$t('member_wait_deposit.cancel'),
        confirmButtonColor: '#009688',
      }).then(async (result) => {
        if (result.isConfirmed) {

          this.loadingSubmit = true
          let params = {
            stampDepositId: data.id,
            amount: parseFloat(data.action.amount),
            member_id: data.action.member_id,
            promotion_id: data.action.promotion_id,
          }

          try {
            const depositGames = await this.$store.dispatch('addDepositGames', params)
            await this.getDepositData(this.pagination.page, this.pagination.itemsPerPage)
            this.$swal.fire(this.$t('member_wait_deposit.deposit_complete'), '', 'success')
            this.memberId = ''
            this.typeItem = ''
            this.waitCredit = ''
            this.amount = ''
            this.promotion = ''
            this.afterAmount = ''
          } catch (e) {
            if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
              this.$swal.fire({
                icon: 'error',
                title: e.message,
              })
            }
          }
          this.loadingSubmit = false

        }
      })
    },
    async getCredit() {
      let rs = await this.$store.dispatch('getMemberWallet', this.memberId)
      this.waitCredit = rs.filter(wallet => wallet.wallet === 'MAIN')[0].balance
      this.changeType()
    },
    async changeType() {
      if (this.typeItem === 'YES') {
        this.afterAmount = parseFloat(this.waitCredit) + parseFloat(this.amount)
      } else if (this.typeItem === 'NO') {
        this.afterAmount = -(parseFloat(this.amount) - parseFloat(this.waitCredit))
      }
      if (isNaN(this.afterAmount)) {
        this.afterAmount = 0
      }
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getDepositData(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.currentPage = 1
    },
    refreshdata() {
      // this.$socket.$subscribe('new_deposit', data => {
      //   console.log(data)

      //   this.statement.unshift({
      //     id: data.id,
      //     amount: data.amount,
      //     before_amount: data.before_amount,
      //     type: data.amount > 0,
      //     member: data.member ? data.member.phone : null,
      //     admin: data.admin ? data.admin.name : 'AUTO',
      //     date: data.created_at ? moment(data.created_at).format('YY-MM-DD HH:mm') : null,
      //     total: data.after_amount ? data.after_amount : data.amount,
      //   })
      // })
      // this.$socket.$subscribe('update_deposit', data => {
      //   console.log(data)
      //   const index = this.statement.findIndex(obj => obj.id === data.id)
      //   this.statement[index].id = data.id
      //   this.statement[index].before_amount = data.before_amount
      //   this.statement[index].bonus = data.bonus
      //   this.statement[index].username = data.member ? data.member.phone : ''
      //   this.statement[index].bank = data.accountbank ? data.accountbank.bank : ''
      //   this.statement[index].bank_account = data.accountbank ? data.accountbank.bank_account : ''
      //   this.statement[index].admin_name = data.admin ? data.admin.name : ''
      //   this.statement[index].date = moment(data.created_at).format('YY-MM-DD HH:mm')
      //   this.statement[index].total = data.amount + data.bonus
      // })
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    async getPromotionUse() {
      this.loadingPromotion = true

      try {
        const rs = await this.$store.dispatch('getPromotionUse')
        this.promotionItems = rs
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }

      this.loadingPromotion = false
    },
    changePromotion(e) {
      this.promotion = e
    },
    async cancelStampDeposit(item, id, match) {
      this.loadingCancel = true
      this.$swal.fire({
        title: this.$t('member_wait_deposit.confirm_delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('member_wait_deposit.delete'),
        cancelButtonText: this.$t('cancel'),
        confirmButtonColor: '#ff4c51',
        confirmButtonWidth: 150
      }).then(async (result) => {
        if (result.isConfirmed) {

          try {
            const data = {
              stampDepositId: item.id,
            }
            const rs = await this.$store.dispatch('cancelDepositGames', data)
            this.$swal.fire(this.$t('member_wait_deposit.delete_complete'), '', 'success')
            await this.getDepositData(this.pagination.page, this.pagination.itemsPerPage)
          } catch (e) {
            if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
              this.$swal.fire({
                icon: 'error',
                title: e.message,
              })
            }
          }

        }
      })
      this.loadingCancel = false
    },
    displayFullImage(image){
      this.$swal.fire({
        imageUrl: image,
        imageAlt: image || 'Slip image',
      })
    }
  },
}
</script>
<style scoped>
.img-bank {
  margin-top: 8px;
  padding: 0 5px 0 0 !important;
}

.detail-bank {
  margin-top: 0px;
  padding: 0 !important;
}

.detail-bank-p {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  text-align: left;
}

.amount-col {
  display: flex;
  justify-content: space-between;
}

.amount-col span i {
  position: relative;
  margin-left: 30px;
}

@media (max-width: 1400px) {
  .amount-col span i {
    position: relative;
    margin-left: 0 !important;
  }
}

@media (max-width: 550px) {
  .img-bank {
    margin-top: 20px;
  }

  .detail-bank {
    margin-top: 0px;
    padding-bottom: 15px !important;
  }

  .detail-bank-p {
    text-align: center;
  }

  .amount-col span i {
    position: relative;
    margin-left: 0;
  }
}

.preview-slip-img {
  width: 56px;
  height: 56px;
  object-fit: fill;
  cursor: pointer;
}
</style>
